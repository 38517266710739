<template>
<div class="liff-me-index-page">
  <template v-if="!loading">
    <div class="page__header">
      <a-row>
        <a-col :span="4" :style="{ minWidth: '48px' }">
          <a-avatar
            class="header-avatar"
            :src="userData.info.avatar!=null ? userData.info.avatar.uri : require('@/assets/icons/avatar.png')"
            @click="$router.push({ name: 'LiffMeSetting' })"
          />
        </a-col>

        <a-col :span="20">
          <div class="header-info">
            <div class="info-text">{{ DateUtil.sayhi() }}，</div>
            <div class="info-name">{{ userData.info.name }}</div>
          </div>
        </a-col>
      </a-row>
    </div>

    <!-- 課堂統計 -->
    <div class="page__section">
      <a-row :gutter="12">
        <a-col :span="12">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-dumbbell" :style="{ color: '#F44336' }"></span>
              </span>
              完成課堂
            </div>
            <div class="card-text">
              {{ rollcallsData.filter(item => item.type === 'lession_pre').length }}
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-clock-outline" :style="{ color: '#F44336' }"></span>
              </span>
              運動總時數
            </div>
            <div class="card-text">
              {{ rollcallsData.filter(item => item.type === 'lession_pre').length * 1 }}
              <span class="text-unit">小時</span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- 課堂統計 -->
    
    <!-- 運動總覽 -->
    <div class="page__section">
      <div class="section-title">運動總覽</div>
      
      <a-row :gutter="12">
        <a-col :span="12">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-chart-line-variant" :style="{ color: '#F44336' }"></span>
              </span>
              今日血壓
            </div>
            <div class="card-text">
              <template v-if="generalStat.sbp || generalStat.dbp">
                {{ generalStat.sbp }} / {{ generalStat.dbp }}
              </template>
              <template v-else>
                無資料
              </template>
            </div>
          </div>
        </a-col>

        <a-col :span="12">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-heart-pulse" :style="{ color: '#F44336' }"></span>
              </span>
              今日心率
            </div>
            <div class="card-text">
              <template v-if="generalStat.heartRate">
                {{ generalStat.heartRate }}
              </template>
              <template v-else>
                無資料
              </template>
            </div>
          </div>
        </a-col>

        <a-col :span="24">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-history" :style="{ color: '#F44336' }"></span>
              </span>
              血壓歷史
            </div>

            <LineChart
              :chartData="bloodPressureChartData"
              :options="chartOptions"
              :style="{ height: '180px' }"
            />
          </div>
        </a-col>

        <a-col :span="24">
          <div class="chart-card">
            <div class="card-title">
              <span class="title-icon">
                <span class="icon mdi mdi-history" :style="{ color: '#F44336' }"></span>
              </span>
              心率歷史
            </div>

            <LineChart
              :chartData="heartRateChartData"
              :options="chartOptions"
              :style="{ height: '180px' }"
            />
          </div>
        </a-col>
      </a-row>
    </div>
    <!-- 運動總覽 -->

    <!-- 訓練內容 -->
    <div class="page__section">
      <div class="section-title">訓練內容</div>
      <a-select
        class="section-selecter"
        v-model:value="traingRecordCourseId"
        :options="courseOptions"
        @change="getTrainingRecords(traingRecordCourseId)"
      />
      <!-- <div class="section-subtitle">呈現所有課堂中的最新紀錄，點擊卡片後可以查看此運動動作的歷史紀錄</div> -->

      <div
        v-if="slicedTrainingRecordsData.length"
        class="training-action-card-list" 
      >
        <div
          v-for="item in slicedTrainingRecordsData"
          class="training-action-card"
        >
          <a-row>
            <a-col :span="6">
              <div class="card-image">
                <TrainingIcon :actionName="item.action_name" :height="45" />
              </div>
            </a-col>

            <a-col :span="18">
              <div class="card-title">{{ item.action_name }}</div>
              <a-tag
                v-if="item.setList.length"
                class="card-tag"
              >
                {{ item.setList[item.setList.length-1] }}
                <span style="font-size:0.5rem">組</span>
              </a-tag>
              <a-tag
                v-if="item.repList.length"
                class="card-tag"
              >
                {{ item.repList[item.repList.length-1] }}
                <span style="font-size:0.5rem">下</span>
              </a-tag>
              <a-tag
                v-if="item.weightList.length"
                class="card-tag"
                color="warning"
              >
                {{ item.weightList[item.weightList.length-1] }}
                <span style="font-size:0.5rem">公斤</span>
              </a-tag>
            </a-col>

            <a-col
              v-if="item.weightList.length"
              :span="18" :offset="6"
              :style="{ marginTop: '12px' }"
            >
              <LineChart
                :chartData="item.chartData"
                :options="item.chartOption"
                :style="{ height: '80px' }"
              />
            </a-col>
          </a-row>
        </div>

        <div align="center">
          <a-button
            @click="expandMoreTrainingRecordsFlag = !expandMoreTrainingRecordsFlag"
            type="text"
            :style="{ color: '#424242' }"
          >
            <template v-if="expandMoreTrainingRecordsFlag">
              <span class="icon mdi mdi-chevron-up mr-3" :style="{ fontSize: '1rem' }" /> 只顯示前幾項
            </template>
            <template v-else>
              <span class="icon mdi mdi-chevron-down mr-3" :style="{ fontSize: '1rem' }" /> 查看所有訓練紀錄
            </template>
          </a-button>
        </div>
      </div>

      <div v-else>
        <a-empty
          class="mt-10"
          :image="simpleImage"
          description="沒有訓練數據"
        />
      </div>
    </div>
    <!-- 訓練內容 -->
  </template>

  <template v-else>
    <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '70vh' }">
      <a-spin
        tip="載入中"
      />
    </div>
  </template>
</div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { message, Empty } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import moment from 'moment'
import { LineChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'
import TrainingIcon from '/src/components/ui/TrainingIcon'

Chart.register(...registerables);

export default defineComponent({
  components: {
    LineChart,
    TrainingIcon
  },
  setup() {
    const route = useRoute();

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_ME });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Loading */
    const loading = ref(true);

    /* Data */
    const userData = ref('');
    const coursesData = ref([]);
    const courseOptions = ref([]);
    const rollcallsData = ref([]);
    const userInstantSurveyReplyData = ref({});
    // TODO:
    const traingRecordCourseId = ref(null);
    const traingRecordCourseValue = ref({});
    const trainingRecordsData = ref([]);

    /* Statistic */
    // generalStat
    const initialGeneralStat = {
      sbp: null, // 收縮壓
      dbp: null, // 舒張壓
      heartRate: null, // 心率
      attendanceCount: 0, // 簽到次數
      totalLessionCount: 0, // 所有課堂
      completedHwCount: 0, // 完成作業數
      totalHwCount: 0 // 所有作業數
    }
    const generalStat = ref({ ...initialGeneralStat });

    // musclesStat
    const initialMusclesStat = {
      // 背部
      dorsum: 0,
      legs: 0,
      glutes: 0,
      // 前部
      arms: 0,
      pectorals: 0,
      abdomen: 0
    }
    const musclesStat = ref({ ...initialMusclesStat });

    // bpHistory
    const initialBpHistory = {
      dates: [],
      sbpData: [],
      dbpData: [],
      hrData: []
    }
    const bpHistory = ref({ ...initialBpHistory });
    const initBpHistoryState = () => {
      bpHistory.value = {
        dates: [],
        sbpData: [],
        dbpData: [],
        hrData: []
      }
    }

    // init stat data
    const initStatisticState = () => {
      generalStat.value = { ...initialGeneralStat };
      musclesStat.value = { ...initialMusclesStat };
      bpHistory.value.value = { ...initialBpHistory };
    }

    // calculate stat data
    const calculateRollcall = () => {
      const lessionPreData = rollcallsData.value.filter(item => item.type == 'lession_pre');
      // const lessionPostData = rollcallsData.value.filter(item => item.type == 'lession_post');

      if (!lessionPreData.length) return

      // 統計 lession_pre
      // generalStat.value.sbp = lessionPreData[lessionPreData.length-1].contents[0].value;
      // generalStat.value.dbp = lessionPreData[lessionPreData.length-1].contents[1].value;
      // generalStat.value.heartRate = lessionPreData[lessionPreData.length-1].contents[2].value;
      generalStat.value.attendanceCount = lessionPreData.length;

      // 統計 bpHistory
      rollcallsData.value.forEach(item => {
        // 日期
        let dateLabel = moment(item.lession.start_date).format('MM/DD');
        if (item.type === 'lession_pre') {
          dateLabel += '(課前)';
        } else if (item.type === 'lession_post') {
          dateLabel += '(課後)';
        }

        // 收縮壓、舒張壓
        item.contents.forEach(content => {
          if (content.name === '收縮壓') {
            if (bpHistory.value.sbpData.length > 6) {
              bpHistory.value.sbpData.shift();
              bpHistory.value.dates.shift();
            }
            bpHistory.value.sbpData.push(content.value);
            bpHistory.value.dates.push(dateLabel);

            generalStat.value.sbp = content.value;
          }

          if (content.name === '舒張壓') {
            if (bpHistory.value.dbpData.length > 6) {
              bpHistory.value.dbpData.shift();
            }
            bpHistory.value.dbpData.push(content.value);

            generalStat.value.dbp = content.value;
          }
          
          if (content.name === '心率') {
            console.log(bpHistory.value.hrData);
            if (bpHistory.value.hrData.length > 6) {
              bpHistory.value.hrData.shift();
            }
            bpHistory.value.hrData.push(content.value);

            generalStat.value.heartRate = content.value;
          }
        });

        // bpHistory.value.dates.push(moment(item.lession.start_date).format('MM/DD'));
        // bpHistory.value.sbpData.push(item.contents[0].value);
        // bpHistory.value.dbpData.push(item.contents[1].value);
      });
      setBPChartData(bpHistory.value.dates, bpHistory.value.sbpData, bpHistory.value.dbpData);
      setHRChartData(bpHistory.value.dates, bpHistory.value.hrData);

      // TODO: 統計 musclesStat
      if (userInstantSurveyReplyData.value.length) {
        const muscleSoreContentValueList = userInstantSurveyReplyData.value[userInstantSurveyReplyData.value.length-1].contents[0].value;
        console.log(userInstantSurveyReplyData.value)
        muscleSoreContentValueList.forEach(item => {
          if (item == '手/手臂') {
            musclesStat.value.arms = 1;
          }
          if (item == '胸部') {
            musclesStat.value.pectorals = 1;
          }
          if (item == '腰') {
            musclesStat.value.abdomen = 1;
          }
          if (item == '背') {
            musclesStat.value.dorsum = 1;
          }
          if (item == '臀部') {
            musclesStat.value.glutes = 1;
          }
          if (item == '大腿/小腿') {
            musclesStat.value.legs = 1;
          }
        });
      }

      console.log(generalStat.value)
      console.log(bpHistory.value.value)
      console.log(musclesStat.value)
    }

    /* Chart */
    const bloodPressureChartData = ref({
      labels: [],
      datasets: [
        {
          label: '收縮壓',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          tension: 0.4,
          yAxisID: 'y'
        },
        {
          label: '舒張壓',
          data: [],
          borderColor: 'rgb(54, 162, 235)',
          backgroundColor: 'rgb(54, 162, 235)',
          tension: 0.4,
          yAxisID: 'y'
        }
      ]
    });

    const heartRateChartData = ref({
      labels: [],
      datasets: [
        {
          label: '心率',
          data: [],
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgb(255, 99, 132)',
          tension: 0.4,
          yAxisID: 'y'
        }
      ]
    });

    const chartOptions = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: false,
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left'
        }
      }
    }

    const initChartData = () => {
      // bp chart
      bloodPressureChartData.value.labels = [];
      bloodPressureChartData.value.datasets[0].data = [];
      bloodPressureChartData.value.datasets[1].data = [];

      // hr chart
      heartRateChartData.value.labels = [];
      heartRateChartData.value.datasets[0].data = [];
    }

    const setBPChartData = (dates, shpData, dbpData) => {
      bloodPressureChartData.value.labels = dates;
      bloodPressureChartData.value.datasets[0].data = shpData;
      bloodPressureChartData.value.datasets[1].data = dbpData;
    }

    const setHRChartData = (dates, hrData) => {
      heartRateChartData.value.labels = dates;
      heartRateChartData.value.datasets[0].data = hrData;
    }

    /* TODO: Training Records */
    const calculateTrainingRecords = (async() => {
      if (!coursesData.value.length) return
      
      traingRecordCourseId.value = coursesData.value[coursesData.value.length - 1].id;
      await getTrainingRecords(traingRecordCourseId.value);
    });

    // Computed
    const expandMoreTrainingRecordsFlag = ref(false);
    const slicedTrainingRecordsData = computed(() => {
      if (trainingRecordsData.value.length === 0) return []

      if (!expandMoreTrainingRecordsFlag.value) {
        if (trainingRecordsData.value.length > 3) {
          return trainingRecordsData.value.slice(0, 3);
        }
      }

      return trainingRecordsData.value
    });


    /* Api */
    const getUserDataByLineId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userData.value = response.data.result;
      console.log(userData.value)
    });

    const getUserCoursesData = (async () => {
      try {
        const response = await api.v1.course.belongList(userData.value.uid, {
          role: userData.value.role
        });
        coursesData.value = response.data.result;
        console.log(coursesData.value)

        response.data.result.forEach(item => {
          const option = {
            label: item.name,
            value: item.id
          }
          courseOptions.value.push(option);
        });
        console.log(courseOptions.value)
      } catch (err) {
        console.log(err);
      }
    });

    const getUserHomeworks = (async (userId, courseId=null) => {
      const response = await api.v1.user.findUserHomeworks(userId, {
        course_id: courseId
      });
      generalStat.value.completedHwCount = response.data.result.length;
      console.log(response.data.result);
    });

    const getUserRolllcallsData = (async (userId, courseId=null) => {
      try {
        const response = await api.v1.user.findUserRollcalls(userId, {
          course_id: courseId
        });
        rollcallsData.value = response.data.result;
        console.log(rollcallsData.value);
      } catch (err) {
        console.log(err);
      }
    });

    const getUserCourseInstantSurveyReplyList = (async (userId, courseId=null) => {
      const response = await api.v1.user.getCourseInstantSurveyList(userId, courseId);
      userInstantSurveyReplyData.value = response.data.result;
      console.log(userInstantSurveyReplyData.value)

      console.log(response.data.result);
    });
    
    // TODO:
    const getTrainingRecords = (async (courseId) => {
      trainingRecordsData.value = [];

      const courseResponse = await api.v1.course.findTrainingRecords(courseId);
      const userResponse = await api.v1.user.findTrainingRecord(userData.value.uid, { course_id: courseId });

      // set course data
      coursesData.value.forEach(course => {
        if (course.id === courseId) {
          traingRecordCourseValue.value = { ...course };
        }
      });

      // filter out the course which user not attend
      const filteredCourseTrainingRecords = [];
      courseResponse.data.result.forEach(record => {
        rollcallsData.value.forEach(rollcall => {
          if (rollcall.type === 'lession_pre') {
            if (record.course_id===rollcall.course_id && record.lession_id===rollcall.lession_id) {
              filteredCourseTrainingRecords.push(record);
            }
          }
        });
      });

      // combime course & user training records
      filteredCourseTrainingRecords.forEach(crItem => {
        userResponse.data.result.forEach(urItem => {
          if (crItem.id === urItem.training_record_id) {
            crItem.weight = parseInt(urItem.weight);
          }
        });
      });

      // set lession info & sort by lession start date
      const lessions = traingRecordCourseValue.value.lessions;
      const lessionStartDateMap = new Map();
      lessions.forEach(l => {
        lessionStartDateMap.set(l.id, l.start_date);
      });

      filteredCourseTrainingRecords.forEach(record => {
        if (lessionStartDateMap.has(record.lession_id)) {
          record.start_date = lessionStartDateMap.get(record.lession_id);
        }
      });

      const result = filteredCourseTrainingRecords.sort((a, b) => moment(a.start_date) - moment(b.start_date));
      console.log(result)
      
      // re-structure with action name object
      let trainingRecordObject = {};
      result.forEach(item => {
        console.log(item);

        if (!Object.keys(trainingRecordObject).includes(item.action_name)) {
          trainingRecordObject[item.action_name] = {
            action_name: item.action_name,
            repList: [],
            setList: [],
            weightList: [],
            chartData: {
              labels: [],
              datasets: [
                {
                  label: '公斤',
                  data: [],
                  borderColor: '#fa8c16',
                  backgroundColor: '#fa8c16',
                  tension: 0.4,
                  yAxisID: 'y'
                }
              ]
            },
            chartOption: {
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                x: {
                  ticks: {
                    display: false
                  }
                }
              }
            }
          }
        }

        // FIXME: 目前只有呈現次數，時間、力竭無法呈現
        trainingRecordObject[item.action_name].repList.push(item.rep);
        trainingRecordObject[item.action_name].setList.push(item.set);

        if (item.weight) {
          trainingRecordObject[item.action_name].weightList.push(item.weight);
          trainingRecordObject[item.action_name].chartData.labels.push(item.start_date);
          trainingRecordObject[item.action_name].chartData.datasets[0].data.push(item.weight);
        }
      });

      // convert trainingRecordsData from object to object-array
      Object.keys(trainingRecordObject).forEach(key => {
        trainingRecordsData.value.push(trainingRecordObject[key]);
      });
      
      console.log(trainingRecordsData.value)
    });

    /* Mounted */
    onMounted(async () => {
      initBpHistoryState();
      initStatisticState();
      initChartData();

      await initLiff();
      await getUserDataByLineId();
      await getUserCoursesData();
      await getUserHomeworks(userData.value.uid);
      await getUserRolllcallsData(userData.value.uid);
      await getUserCourseInstantSurveyReplyList(userData.value.uid);
      calculateRollcall();
      calculateTrainingRecords();

      loading.value = false;
    });

    return {
      /* Loading */
      loading,

      /* Data */
      userData,
      courseOptions,
      rollcallsData,
      traingRecordCourseId,
      traingRecordCourseValue,
      trainingRecordsData,
      expandMoreTrainingRecordsFlag,
      slicedTrainingRecordsData,

      /* Statistic */
      generalStat,
      musclesStat,
      bpHistory,

      /* Chart */
      bloodPressureChartData,
      heartRateChartData,
      chartOptions,
      initChartData,

      /* Training Record */
      getTrainingRecords,

      /* Api */
      getUserRolllcallsData,

      /* Utils */
      DateUtil,

      /* Empty */
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.liff-me-index-page {
  min-height: 100vh;
  padding: 16px;

  .page__header {
    margin-bottom: 16px;

    .header-avatar {
      width: 45px;
      height: 45px;
      
      img {
        width: 45px;
        height: 45px;
      }
    }

    .header-info {
      .info-text {
        font-size: 1rem;
        line-height: 1.1rem;
        color: #878787;
        letter-spacing: 0.05rem;
      }

      .info-name {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.05rem;
      }
    }
  }

  .page__section {
    margin-bottom: 12px;
    position: relative;

    .section-title {
      color: #212121;
      font-size: 1.45rem;
      font-weight: 500;
      letter-spacing: 0.08rem;
      margin-bottom: 8px;
    }

    .section-selecter {
      border: none !important;
      
      width: 130px;
      position: absolute;
      right: 0;
      top: 3px;

      .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
      }
    }

    .chart-card {
      background: #fafafa;
      // border: 2px solid #ececec;
      // box-shadow: 0px 2.90152px 2.90152px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 16px;
      margin-bottom: 12px;

      .card-title {
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0.04rem;
        margin-bottom: 8px;

        .title-icon {
          display: inline-block;
          width: 28px !important;
          height: 28px !important;
          background: white;
          border-radius: 100%;
          margin-right: 4px;
          text-align: center;

          .icon {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      .card-text {
        color: #212121;
        font-size: 1.7rem;
        font-weight: 450;
        text-align: center;

        .text-unit {
          font-size: 1.2rem;
        }
      }
    }
  }

  /* Training action card style */
  .training-action-card-list {
    .training-action-card {
      border: 2px solid #ececec;
      // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      padding: 12px;
      margin-bottom: 16px;

      .card-image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 8px;
      }

      .card-title {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.05rem;
        margin-bottom: 4px;
      }

      .card-tag {
        font-size: .8rem;
      }
    }
  }
}
</style>